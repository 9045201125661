import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import {CircularProgress} from '@mui/icons-material'
import { CircularProgress } from '@mui/material';
import ColorButton  from '../components/ColorCode';
import { apiInstance } from 'src/httpClient/httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  height: 300,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


const UserModal = ({handleClose, getAllColor, isColorEdit, singleColordata }) => {
  const [hashCode, setHashCode] = useState(singleColordata?.hashCode || '');
  // console.log('hashCode: ', hashCode);
  const [name, setName] = useState(singleColordata?.name || '');
  const [loader, setLoader] = useState(false);
  const [color, setColor] = useState({});

  const newColor = async () => {
    const addColor = {
      hashCode:color.hex?color.hex:hashCode,
      name: name
    };
    console.log('-----',addColor);
    setLoader(true);
    if (isColorEdit) {
      try {
        console.log('-----try---');
        const response = await apiInstance.put(`colour/${singleColordata?._id}`, addColor);
        console.log('resssssss---------put---------------', response);
        setLoader(false);
        getAllColor();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    } else {
      try {
        const response = await apiInstance.post('colour', addColor);
        console.log('resssss-----post-------', response);
        setLoader(false);
        getAllColor();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isColorEdit ? 'Edit Color' : 'Add Color'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button color="secondary" onClick={handleClose} style={{borderRadius:2}}>X</Button> */}
            <CloseRoundedIcon onClick={isColorEdit ? handleClose : handleClose } />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </Grid>
          <Grid container item xs={12}>
             {/* <ButtonExample/> */}
             <Grid container item xs={11}>
            {isColorEdit ? 
            <TextField
              label={'Hex code'}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
              onChange={(e) => {
                setHashCode(e.target.value);
              }}
              value={color.hex}
            />
             : 
            <TextField
            label={'Hex code'}
            id="name"
            type="text"
            style={{ width: '100%', height: '100%' }}
            onChange={(e) => {
              setHashCode(e.target.value);
            }}
            value={hashCode?hashCode:color.hex}
          />}
             </Grid>
             <Grid container item xs={1}>
             <ColorButton
             hashCode={hashCode}
             onChange={setColor}
             />
             </Grid>
             
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          disableElevation
          onClick={newColor}
        >
          {isColorEdit ? "Edit color" :"Add color"}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default UserModal;
