import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { apiInstance } from 'src/httpClient/httpClient';
import UploadImage from '../pages/UploadImage'
import {getToken} from '../utils/common'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height:390,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


const UserModal = ({handleClose, getAlluser, isUserEdit, signleUserdata }) => {
    
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

  const [name, setName] = useState(signleUserdata?.name || '');
  const [email, setEmail] = useState(signleUserdata?.email || '');
  const [phone, setPhone] = useState(signleUserdata?.phone || '');
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const newUser = async () => {

    const formData = new FormData();
    formData.append("name",name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", values.password);
    formData.append("image", uploadFile);
    formData.append("loginType", 'email');

    
    for (var pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    setLoader(true);
    if (isUserEdit) {
      try {
        console.log('-----try---');
        const response = await apiInstance.put(`user/${signleUserdata?._id}`, formData);
        console.log('ressssssssssssssssssssssssssssssss===', response);
        setLoader(false);
        getAlluser();
        handleClose();
      } catch (error) {
        setLoader(false);

        console.log('---------------your-------------', error.response);
      }
    } else {
      try {
        console.log('getToken: ', getToken);
        const response = await apiInstance.post('user/upload', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            'X-auth-token':getToken()
          }
        } );
        console.log('ressssssssssssssssssssssssssssssss===', response);
        setLoader(false);
        getAlluser();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    }
  };

  const handleUpload = (e) => {
    console.log('e.target.files[0]: ', e.target.files[0]);
    setFile( URL.createObjectURL(e.target.files[0]))
    setUploadFile(e.target.files[0])
    
  }
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isUserEdit ? 'Edit User' : 'Add User'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={isUserEdit ? handleClose : handleClose } />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={6}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              label={'Email'}
              onChange={(e) =>{
                setEmail(e.target.value)
              }}
              value={email}
              id="email"
              type="email"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>

          <Grid container item xs={6}>
            <TextField
              label={'Phone'}
              onChange={(e)=>{
                setPhone(e.target.value)
              }}
              value={phone}
              id="phone"
              type="number"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
          <Grid container item xs={6}>
          {isUserEdit ? undefined :
            <FormControl sx={{  width: '25ch' }} variant="outlined">
                     <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> 
        
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    sx={{width:330}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        
                        >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
             }
          </Grid>
          <Grid container item xs={12}>
            <UploadImage onChange={handleUpload} uploadFile={uploadFile} file={file} signleUserdata={signleUserdata} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          onClick={newUser}
          disableElevation
        >
          {isUserEdit? "Edit user": "Add user"}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default UserModal;
