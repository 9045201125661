import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import LoginImage from '../attech/image/LoginImage.svg';
import OtpForm from 'src/sections/auth/otp/OtpForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },

}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth:600,
  display: 'flex',
  justifyContent: 'center',
  alignItems:"center",
  borderRadius:'0px',
  // borderTopRightRadius:'10px',
  background:'#fff1ec',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth:900,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  // borderTopLeftRadius:100,
}));

// ----------------------------------------------------------------------

export default function Otp() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {/* <Logo /> */}

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography> */}
            <img src={LoginImage} alt="login"/>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
          <Logo sx={{mb:2,flexDirection:'column',justifyContent:"start"}} />
            <Typography variant="h4" gutterBottom>OTP         
            </Typography>
            </div>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Please enter your OTP</Typography>

            {/* <AuthSocial /> */}

            {/* <LoginForm /> */}

            {/* <ForgetEmail/>  */}

            <OtpForm/>
            
            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
