import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import {CircularProgress} from '@mui/material';
import { apiInstance } from 'src/httpClient/httpClient';
import { setUserSession } from 'src/utils/common';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email:"",
      password:"" ,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const data = {
        "email": values.email,
        "password": values.password,
        // "remember": true
  
      };
      console.log('data: ', data);
      setLoader(true)
      try {
        const res = await apiInstance.post('auth/login-admin', data);
        console.log('res: ', res);
  
        if (res.status === 200) {
          setUserSession(
            res.data.data.token,
            res.data.data.name,
            res.data.data.email,
            res.data.data.phone,
            res.data.data
          );
          console.log('resssssssss status',res.status);
          setLoader(false);
          navigate('dashboard/user');
        }
      } catch (error) {
        setLoader(false);
        setErrorMessage('email or password invalid!');
        console.log('error===', error.response);
      }
      // navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} >
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            // value={email}
            // onChange={(e) => {
            //   setEmail(e.target.value);
            // }}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'bi:eye-fill' : 'bi:eye-slash-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            // value={password}
            // onChange={(e) => {
            //   setPassword(e.target.value);
            // }}
          />
        </Stack>
        {errorMessage && (
            <div style={{color:"red"}} className="error">
              {' '}
              {errorMessage}{' '}
            </div>
          )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="forget" underline="hover">
            Forgot password?
          </Link>
        </Stack>
      

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Login
          {loader  && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} loading={isSubmitting} />}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
