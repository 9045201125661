import { useState,useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UploadImage from '../pages/UploadImage'
// import {CircularProgress} from '@mui/icons-material'
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { apiInstance } from 'src/httpClient/httpClient';
import { MenuItem, Select,} from '@mui/material';
import reactImageSize from 'react-image-size';
import {getToken} from '../utils/common';
// import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height: 430,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  // overflow: 'scroll'
  overflowY: 'auto'
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

// const Input = styled('input')({
//   display: 'none',
// });


const ImageModal = ({handleClose, getImage, isImageEdit, signleImagedata,signleUserdata }) => {
  console.log('signleImagedata: ', signleImagedata);

  const [name, setName] = useState(signleImagedata?.name || '');
  const [category, setCategory] = useState(signleImagedata?.category._id || '');
  console.log('signleImagedata?.category._id: ', signleImagedata?.category);
  const [colour, setColor] = useState(signleImagedata?.colour[0]?._id || '');
  const [resolition, setResolition] = useState(signleImagedata?.resolution || {
    width: '-' ,
    height: '-' 
  });
  const [size, setSize] = useState(signleImagedata?.size || '');
  const [loader, setLoader] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [file, setFile] = useState(null);
  const [post,setPost] = useState([]);
  const [all,setAll] = useState([]);


  const newImage = async () => {
  const formData = new FormData();

  formData.append("name",name);

  if(uploadFile && uploadFile !== ''){

    formData.append("image", uploadFile);
  }
  formData.append("category",category);
  formData.append("colour",colour);
  // formData.append("resolution",`${resolition.width} * ${resolition.height}` );
  formData.append("width",resolition.width );
  formData.append("height",resolition.height );
  if(uploadFile?.size && uploadFile?.size !== ''){

   
    formData.append("size", uploadFile?.size);
  }
  // formData.append("thumbUrl",thumbnail);

    for (var pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    setLoader(true);
    if (isImageEdit) {
      try {
        console.log('-----try---');
        console.log('getToken',getToken());
        const response = await apiInstance.put(`image/${signleImagedata?._id}`, formData,{
          headers: {
            'X-auth-token':getToken(),
            "Content-Type": "multipart/form-data",
          }
        })
        console.log('ressssssssssssssssssssssssssssssss===', response);
        setLoader(false);
        getImage();
        handleClose();
      } catch (error) {
        setLoader(false);
        handleClose();
        console.log('---------------your-------------', error.response);
      }
    } else {
      try {
        const response = await apiInstance.post('image', formData);
        console.log('ressssssssssssssssssssssssssssssss===', response);
        setLoader(false);
        getImage();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    }
  };

  const handleUpload = async (e) => {
    console.log('e.target.files[0]: ', e.target.files[0]);
    setFile( URL.createObjectURL(e.target.files[0]))
    console.log('URL.createObjectURL(e.target.files[0]): ', URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0])
    setSize(e.target.files[0].size)


    setTimeout(() => {
      console.log('------------------------');
      reactImageSize( URL.createObjectURL(e.target.files[0]))
      .then((imgData) => {
        console.log('e.target.files[0]): ', e.target.files[0]);
        console.log('imgData: ', imgData);
        setResolition(imgData)
        // setImgReso(imgData)
       
      })  .catch((errorMessage) => console.log('errorMessage',errorMessage));
    
    },1000)
  }

  useEffect(()=>{
    getCategory();
    getAllColor();
  },[])

  const getCategory = async () => {
    try {
      const response = await apiInstance.get('category/get-all?categorytype=image');
      console.log('category-------response----------', response);
      setPost(response.data.data);
    } catch (error) {
      console.log('resss====================', error.response);
    }
  };


  const getAllColor = async () => {
    try {
      const res = await apiInstance.get('colour/get-all');
      console.log('response--------color', res);
      setAll(res.data.data);
    } catch (error) {
      console.log('error---------color', error.response);
    }
  };

 
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isImageEdit ? 'Edit Image' : 'Add Image'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button color="secondary" onClick={handleClose} style={{borderRadius:2}}>X</Button> */}
            <CloseRoundedIcon onClick={isImageEdit ? handleClose : handleClose } />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={6} flexDirection='column'>
            <UploadImage onChange={handleUpload} uploadFile={uploadFile} file={file} signleUserdata={signleUserdata}   />
          </Grid>
          <Grid container item xs={6}>
          <TextField
            onChange={(e)=>{
              setName(e.target.value)
            }}
            label={'Name'}
            id="Name"
            type="text"
            value={name}
            style={{ width: '100%', height: '100%' }}
            />
          </Grid>

          <Grid container item xs={6}>
          <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="category"
                label="Category"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                style={{ width: '100%', height: '100%' }}
                value={category}
              >
                {post &&
                  post?.map((e) => {
                    return <MenuItem value={e._id}>{e.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid container item xs={6}>
          <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="color"
                label="Color"
                onChange={(e) => {
                  setColor(e.target.value);
                }}
                style={{ width: '100%', height: '100%' }}
                value={colour}
              >
                {all &&
                  all?.map((e) => {
                    return <MenuItem value={e._id}>{e.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid container item xs={6}>
          <TextField
              label={'Resolition'}
              id="Resolition"
              type="text"
              onChange={(e) => {
                setResolition(e.target.value);
              }}
              disabled
              // value={imgReso?.width +' * ' + imgReso?.height }
              value={resolition ? resolition?.width +' * ' + resolition?.height 
              : '-' }
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>

          <Grid container item xs={6}>
          <TextField  
              label={'Size'}
              id="Size"
              type="text"
              onChange={(e) => {
                setSize(e.target.value);
              }}
              value={size}
            
              style={{ width: '100%', height: '100%' }}
              disabled
            />
          </Grid>
         
        </Grid>

        <Button
          variant="contained"
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          onClick={newImage}
          disableElevation
        >
          {isImageEdit ? "Edit image" : "Add image"}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default ImageModal;
