import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Colors from './pages/Colors';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Category from './pages/Category';
import Images from './pages/Images';
import DashboardApp from './pages/DashboardApp';
import Forget from './pages/Forget'
import Otp from './pages/Otp';
import ResetPassword from './pages/ResetPassword';
import Ringtone from '../src/pages/Ringtone'
// import Otp from './pages/Otp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'category', element: <Category /> },
        { path: 'colors', element: <Colors /> },
        { path: 'images', element: <Images /> },
        { path: 'ringtone', element: <Ringtone /> },
     
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Login /> },
        { path: 'forget', element: <Forget /> },    
        { path: 'reset', element: <ResetPassword /> },    
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'otp', element: <Otp/> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
