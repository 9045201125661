import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import {CircularProgress} from '@mui/material';
import { apiInstance } from 'src/httpClient/httpClient';
// ----------------------------------------------------------------------

export default function ForgetEmail() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email:"",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const data = {
        "email": values.email,
      };
      console.log('data: ', data);
      setLoader(true)
      try {
        const res = await apiInstance.post('auth/forgot-PasswordAdmin', data);
        console.log('res: ', res);
        setLoader(false);
        navigate('/otp', {state:{
          email:data.email
      }});
      }catch (error) {
        setLoader(false);
        console.log('error------------print',error.response);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} >
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Add your valid Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
           
          />
        </Stack>
        {errorMessage && (
            <div style={{color:"red"}} className="error">
              {' '}
              {errorMessage}{' '}
            </div>
          )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Submit
          {loader  && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} loading={isSubmitting} />}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
