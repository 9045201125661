import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import MyLogo from '../attech/image/Logo.png'

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="/">
      <Box component="img" src={MyLogo} sx={{ width:70, height:70, ...sx }} />
    </RouterLink>
  );
}
