import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { apiInstance } from 'src/httpClient/httpClient';
import UploadImage from '../pages/UploadImage'
import {getToken} from '../utils/common'
import { MenuItem, Select,} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:700,
  height:300,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};



const RingtoneModal = ({handleClose, getRingtone, isRingEdit, singleRingtonedata,signleUserdata  }) => {  
  const [name, setName] = useState(singleRingtonedata?.name || '');
  const [category, setCategory] = useState(singleRingtonedata?.category || '');
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [post,setPost] = useState([]);
  const [ringToneFile, setRingToneFile] = useState(null)
  const [uploadRingToneFile, setUploadRingToneFile] = useState(null)

  const newUser = async () => {

    const formData = new FormData();
    formData.append("name",name);
    formData.append("category", category);
    formData.append("ringtone", uploadRingToneFile);
    formData.append("image", uploadFile);
    formData.append("loginType", 'email');

    setLoader(true);
    if (isRingEdit) {
      try {
        const response = await apiInstance.put(`ringtone/${singleRingtonedata?._id}`, formData);
        setLoader(false);
        getRingtone();
        handleClose();
      } catch (error) {
        setLoader(false);

        console.log('---------------your-------------', error.response);
      }
    } else {
      try {
        const response = await apiInstance.post('ringtone', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            'X-auth-token':getToken()
          }
        } );
        setLoader(false);
        getRingtone();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    }
  };

  const handleUpload = (e) => {
    setFile( URL.createObjectURL(e.target.files[0]))
    setUploadFile(e.target.files[0])   
  }
  
  const handleRingTone = (e) => {
    setRingToneFile( URL.createObjectURL(e.target.files[0]))
    setUploadRingToneFile(e.target.files[0])   
  }

  useEffect(()=>{
    getCategory();
  },[])

  const getCategory = async () => {
    try {
      const response = await apiInstance.get('category/get-all?categorytype=ringtone');
      setPost(response.data.data);
    } catch (error) {
      console.log('resss====================', error.response);
    }
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isRingEdit ? 'Edit Ringtone' : 'Add Ringtone'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={isRingEdit ? handleClose : handleClose } />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={6}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
          <Grid container item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Category"
                label="Category"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                style={{ width: '100%', height: '100%' }}
                value={category}
              >
                {post &&
                  post?.map((e) => {
                    return <MenuItem value={e._id}>{e.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid container item xs={6}>
         
            <input type="file" name="test" id="testFile" onChange={handleRingTone}   />
          </Grid>
          <Grid container item xs={6}>
            <UploadImage onChange={handleUpload} uploadFile={uploadFile} file={file} signleUserdata={signleUserdata} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          onClick={newUser}
          disableElevation
        >
          {isRingEdit? "Edit ringtone": "Add ringtone"}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default RingtoneModal;
