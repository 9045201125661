import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,Modal } from '@mui/material';
// component
import {apiInstance} from './../../../httpClient/httpClient/index';
import Iconify from '../../../components/Iconify';
import UserModal from '../../../components/UserModal';
import CategoryModal from '../../../components/CategoryModal';
import ColorModal from '../../../components/ColorModal';
import ImageModal from '../../../components/ImageModal';
import RingtoneModal from '../../../components/RingtoneModal';

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  console.log('props: ', props.getRingtone);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState({});
  
  const handleOpen = (data) => {
    setNewData(data);
    setTimeout(() => {}, 2000);
    setOpen(true);
    setIsOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

const handleDelete = async () => {
  if(props.type == 'user'){
    try{
    const res = await apiInstance.delete(`user/hard-delete/${props.data?._id}`);
    console.log('response:',res);
    props.getAlluser();
  } catch (e) {
    console.log('e:',e.response);
  }
} else if(props.type == 'category'){
  try{
    const res = await apiInstance.delete(`category/delete/${props.data?._id}`);
    console.log('res--------delect: ', res);
    props.getAllcategory();
  }catch (e){
    console.log('e:',e.response);
  }
} else if(props.type == 'color'){
  try{
    const res = await apiInstance.delete(`colour/delete/${props.data?._id}`);
    console.log('color------------delect: ', res);
    props.getAllColor();
  }catch (e){
    console.log('color-delect',e.response);
  }
} else if(props.type == 'image'){
  try{
    const res = await apiInstance.delete(`image/delete/${props.data?._id}`);
    console.log('image---------delect:' ,res);
    props.getImage();
  }catch (e){
    console.log('Error----------delect',e.response);
  }
} else if(props.type == 'ringtone'){
  try{
    const res = await apiInstance.delete(`ringtone/delete/${props.data?._id}`);
    console.log('ringtone----delete',res);
    props.getRingtone();
  }catch(e){
    console.log('ringtone-------------error',e.response);
  }
}
}
  
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}
          onClick={() => handleOpen(props.data)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
          {props.type == 'user' ? <UserModal
          isUserEdit
          getAlluser={props.getAlluser}
          signleUserdata={props.data}
          handleClose={handleClose}
          /> : props.type == 'category' ? <CategoryModal
          isCategoryEdit
          handleClose={handleClose}
          getAllcategory={props.getAllcategory}
          singleCategorydata={props.data}
          signleUserdata={props.data}
        /> : props.type == 'color' ? <ColorModal
          isColorEdit
          handleClose={handleClose}
          singleColordata={props.data}
          getAllColor={props.getAllColor}
        /> : props.type == 'image' ? <ImageModal
          isImageEdit
          handleClose={handleClose}
          signleImagedata={props.data}
          getImage={props.getImage}
          signleUserdata={props.data}
        /> : props.type == 'ringtone' ? <RingtoneModal
           isRingEdit 
           singleRingtonedata={props.data}
           getRingtone={props.getRingtone}
           handleClose={handleClose}
        />:null}
      </Modal>
    </>
  );
}
