
import React, { useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const Input = styled('input')({
  display: 'none',
});

const UploadAndDisplayImage = ({onChange, file, uploadFile,signleUserdata,}) => {
 
  return (
    <>
      {/* {selectedImage && (
        <div>
        <img alt="not fount" width={"250px"}  src={file} />
        <button onClick={()=>setSelectedImage(null)}>Remove</button>
        </div>
      )}
      <br /> */}
     
      <br /> 
      <div> 
      <label htmlFor="contained-button-file"  style={{display:"flex",alignItems:"start"}}>
        <Input accept="image/*" id="contained-button-file" onChange={onChange} multiple type="file" />
        <div>
        <Button variant="contained" component="span" sx={{borderRadius:0}}>
          Upload Image
        </Button>
        </div>
      </label>
      </div>
      
       {/* <Button variant="contained" type="file" onChange={onChange}  component="span">
    Upload
  </Button> */}
      {file ?  
      <div >
        <img src={file} alt='' style={{paddingTop:10}} />  

      </div> : signleUserdata ?  <img src={signleUserdata?.image} alt='img' style={{width:200,height:250,paddingTop:10}} /> : null }

      {/* {signleUserdata && <img src={signleUserdata?.image} alt='img' style={{width:200,height:250,paddingTop:10}} />} */}


    </>
  );
};

export default UploadAndDisplayImage;








// {(event) => {
//   console.log(event.target.files[0]);
//   setSelectedImage(event.target.files[0]);
// }}