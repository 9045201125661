import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UploadImage from '../pages/UploadImage';
import { apiInstance } from 'src/httpClient/httpClient';
import { CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:600,
  height: "auto",
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


const CategoryModal = ({handleClose, getAllcategory, isCategoryEdit, singleCategorydata,signleUserdata }) => {
    
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


  const [name, setName] = useState(singleCategorydata?.name || '');
  const [categorytype, setCategorytype] = useState(singleCategorydata?.categorytype || '');
  const [ringtone,setRingtone] = useState("")
  const [loader, setLoader] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);  
  const [file, setFile] = useState(null);

  const newCategory = async () => {
    const formData = new FormData();
    formData.append("name",name);
    formData.append("categorytype",categorytype);
    formData.append("image", uploadFile);
    for (var pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    setLoader(true);
    if (isCategoryEdit) {
      try {
        console.log('-----try---');
        const response = await apiInstance.put(`user/${category?._id}`, formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          }
        } )
        console.log('Put------Resonse===', response);
        setLoader(false);
        getAllcategory();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    } else {
      try {
        const response = await apiInstance.post('category', formData);
        console.log('post-------Response===', response);
        setLoader(false);
        getAllcategory();
        handleClose();
      } catch (error) {
        setLoader(false);
        console.log('---------------your-------------', error.response);
      }
    }
  };


  const handleUpload = (e) => {
    setFile( URL.createObjectURL(e.target.files[0]))
    setUploadFile(e.target.files[0])
    
  }

  console.log('signleUserdata:',signleUserdata);

 

  const handleOn = (event) => {
    setCategorytype(event.target.value);
    setRingtone(event.target.value)
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isCategoryEdit ? 'Edit Category' : 'Add Category'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={isCategoryEdit ? handleClose : handleClose } />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={4}>
           <UploadImage onChange={handleUpload} uploadFile={uploadFile} file={file} signleUserdata={signleUserdata}  />
          </Grid>
          <Grid container item xs={8}>
            <TextField
               onChange={(e)=>{
                setName(e.target.value)
              }}
              label={'Name'}
              value={name}
              id="name"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
          <Grid container item xs={12}>
          <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">loginType</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={categorytype}
          label="loginType"
          onChange={handleOn}
        >
          <MenuItem value={"image"}>image</MenuItem>
          <MenuItem value={"ringtone"}>ringtone</MenuItem>
        </Select>
      </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={newCategory}
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          disableElevation
        >
         {isCategoryEdit? "Edit category" : "Add category"}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default CategoryModal;





