import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import {CircularProgress} from '@mui/material';

// ----------------------------------------------------------------------

const ResetForm = () => {
  const [passoword,setPassword] = useState('')
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(e.target.value)
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
// -----------------------------------------------------------------------------------------
  const [conformPass, setConformPass] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleOpen = (prop) => (event) => {
    setConformPass({ ...conformPass, [prop]: event.target.value });
    setPassword(e.target.value)
  };

  const OpenClickShowPassword = () => {
    setConformPass({
      ...conformPass,
      showPassword: !conformPass.showPassword,
    });
  };

  const OpenMouse = (event) => {
    event.preventDefault();
  }

  const ButtonClick = async () =>{;
    if (values !== conformPass.password ) {
      alert("Passwords don't match");
      console.log('----------------------');
  } else {
    const data = {
      "password" :passoword
    };
    console.log('data: ', data);
    setLoader(true)
    try {
      const res = await apiInstance.post('auth/forgotPassword-setAdmin', data);
      console.log('res: ', res);
      setLoader(false);
      navigate('/otp', {state:{
        email:data.email
    }});
    }catch (error) {
      setLoader(false);
      console.log('error------------print',error.response);
    }
  }
  }
  
  
  return(
    <>
     <FormControl fullWidth  variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Enter Your New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl fullWidth sx={{marginTop:3}}variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm Your Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={conformPass.showPassword ? 'text' : 'password'}
            value={conformPass.password}
            onChange={handleOpen('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={OpenClickShowPassword}
                  onMouseDown={OpenMouse}
                  edge="end"
                >
                  {conformPass.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Button variant="contained" sx={{padding:1,marginTop:3}} onClick={ButtonClick} >
          Save
        {loader  && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} loading={isSubmitting} />}
        </Button>
    </>
  )
}
export default ResetForm